import React from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { signout } from "../../state/actions/account.actions";
// import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew';

const SideBar = () => {
    const dispatch = useDispatch();
    const handleSignout = () => {
        dispatch(signout());
    };
    return (
        <>
            <div className="mm-sidebar  sidebar-default ">
                <div className="mm-sidebar-logo d-flex align-items-center justify-content-between">
                    <Link to="/" className="header-logo">
                        <img
                            src="assets/images/Redspeed-black.png"
                            className="img-fluid rounded-normal light-logo"
                            alt="logo"
                        />
                        {/* <img
              src="assets/images/Redspeed-white.png"
              className="img-fluid rounded-normal darkmode-logo"
              alt="logo"
            /> */}
                    </Link>
                    <div className="side-menu-bt-sidebar">
                        <i className="fa fa-bars wrapper-menu"></i>
                    </div>
                </div>
                <div className="data-scrollbar" data-scroll="1">
                    <nav className="mm-sidebar-menu">
                        <ul id="mm-sidebar-toggle" className="side-menu">
                            <li className="">
                                <Link to="/" className="svg-icon">
                                    {/* <a href="index.html" className="svg-icon"> */}
                                    <i className="">
                                        <svg
                                            className="svg-icon"
                                            id="mm-dash"
                                            width="20"
                                            xmlns="http://www.w3.org/2000/svg"
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            stroke="currentColor"
                                        >
                                            <path
                                                stroke-linecap="round"
                                                stroke-linejoin="round"
                                                stroke-width="2"
                                                d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6"
                                            />
                                        </svg>
                                    </i>
                                    <span className="ml-2">Home</span>
                                </Link>
                                {/* </a> */}
                            </li>
                            <li className="">
                                <Link to="/customers" className="svg-icon">
                                    {/* <a href="index.html" className="svg-icon"> */}
                                    <i className="">
                                    <svg
                                            className="svg-icon"
                                            id="mm-form-1"
                                            width="20"
                                            xmlns="http://www.w3.org/2000/svg"
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            stroke="currentColor"
                                        >
                                            <path
                                                stroke-linecap="round"
                                                stroke-linejoin="round"
                                                stroke-width="2"
                                                d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2m-3 7h3m-3 4h3m-6-4h.01M9 16h.01"
                                            />
                                        </svg>
                                    </i>
                                    <span className="ml-2">Customers</span>
                                </Link>
                                {/* </a> */}
                            </li>
                            <li className="">
                                <a
                                    href="#app"
                                    className="collapsed svg-icon"
                                    data-toggle="collapse"
                                    aria-expanded="false"
                                >
                                    <i>
                                        <svg
                                            className="svg-icon"
                                            id="mm-app-1"
                                            width="20"
                                            xmlns="http://www.w3.org/2000/svg"
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            stroke="currentColor"
                                        >
                                            <path
                                                stroke-linecap="round"
                                                stroke-linejoin="round"
                                                stroke-width="2"
                                                d="M4 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2V6zM14 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2V6zM4 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2v-2zM14 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2v-2z"
                                            />
                                        </svg>
                                    </i>
                                    <span className="ml-2">
                                        Order Management
                                    </span>
                                    <i className="fa fa-angle-right mm-arrow-right arrow-active"></i>
                                    <i className="fa fa-angle-down mm-arrow-right arrow-hover"></i>
                                </a>
                                <ul
                                    id="app"
                                    className="submenu collapse"
                                    data-parent="#mm-sidebar-toggle"
                                >
                                    <li className="">
                                        <Link
                                            to="/orderoverview"
                                            className="svg-icon"
                                        >
                                            {/* <a href="order-overview.html" className="svg-icon"> */}
                                            <i className="">
                                                <svg
                                                    className="svg-icon"
                                                    id="mm-user-1"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    fill="none"
                                                    viewBox="0 0 24 24"
                                                    stroke="currentColor"
                                                >
                                                    <path
                                                        stroke-linecap="round"
                                                        stroke-linejoin="round"
                                                        stroke-width="2"
                                                        d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z"
                                                    />
                                                </svg>
                                            </i>
                                            <span className="">
                                                Order Overview
                                            </span>
                                        </Link>
                                        {/* </a> */}
                                    </li>
                                    <li className="">
                                        <Link
                                            to="/daily-shipments"
                                            className="svg-icon"
                                        >
                                            {/* <a href="order-overview.html" className="svg-icon"> */}
                                            <i className="">
                                                <svg
                                                    className="svg-icon"
                                                    id="mm-user-1"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    fill="none"
                                                    viewBox="0 0 24 24"
                                                    stroke="currentColor"
                                                >
                                                    <path
                                                        stroke-linecap="round"
                                                        stroke-linejoin="round"
                                                        stroke-width="2"
                                                        d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z"
                                                    />
                                                </svg>
                                            </i>
                                            <span className="">
                                                Daily Shipments
                                            </span>
                                        </Link>
                                        {/* </a> */}
                                    </li>
                                    <li className="">
                                        <Link
                                            to="/rcs"
                                            className="svg-icon"
                                        >
                                            {/* <a href="order-overview.html" className="svg-icon"> */}
                                            <i className="">
                                                <svg
                                                    className="svg-icon"
                                                    id="mm-user-1"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    fill="none"
                                                    viewBox="0 0 24 24"
                                                    stroke="currentColor"
                                                >
                                                    <path
                                                        stroke-linecap="round"
                                                        stroke-linejoin="round"
                                                        stroke-width="2"
                                                        d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z"
                                                    />
                                                </svg>
                                            </i>
                                            <span className="">
                                                RCS
                                            </span>
                                        </Link>
                                        {/* </a> */}
                                    </li>
                                    <li className="">
                                        <Link
                                            to="/cod-report"
                                            className="svg-icon"
                                        >
                                            {/* <a href="order-overview.html" className="svg-icon"> */}
                                            <i className="">
                                                <svg
                                                    className="svg-icon"
                                                    id="mm-user-1"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    fill="none"
                                                    viewBox="0 0 24 24"
                                                    stroke="currentColor"
                                                >
                                                    <path
                                                        stroke-linecap="round"
                                                        stroke-linejoin="round"
                                                        stroke-width="2"
                                                        d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z"
                                                    />
                                                </svg>
                                            </i>
                                            <span className="">
                                                COD Report
                                            </span>
                                        </Link>
                                        {/* </a> */}
                                    </li>
                                    <li className="">
                                        <Link
                                            to="/delivered-cod-report"
                                            className="svg-icon"
                                        >
                                            {/* <a href="order-overview.html" className="svg-icon"> */}
                                            <i className="">
                                                <svg
                                                    className="svg-icon"
                                                    id="mm-user-1"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    fill="none"
                                                    viewBox="0 0 24 24"
                                                    stroke="currentColor"
                                                >
                                                    <path
                                                        stroke-linecap="round"
                                                        stroke-linejoin="round"
                                                        stroke-width="2"
                                                        d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z"
                                                    />
                                                </svg>
                                            </i>
                                            <span className="">
                                                Delivered COD Report
                                            </span>
                                        </Link>
                                        {/* </a> */}
                                    </li>
                                   
                                    
                                </ul>
                            </li>
                            <li className="">
                                <a
                                    href="#trip"
                                    className="collapsed svg-icon"
                                    data-toggle="collapse"
                                    aria-expanded="false"
                                >
                                    <i>
                                        <svg
                                            className="svg-icon"
                                            id="mm-app-1"
                                            width="20"
                                            xmlns="http://www.w3.org/2000/svg"
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            stroke="currentColor"
                                        >
                                            <path
                                                stroke-linecap="round"
                                                stroke-linejoin="round"
                                                stroke-width="2"
                                                d="M4 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2V6zM14 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2V6zM4 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2v-2zM14 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2v-2z"
                                            />
                                        </svg>
                                    </i>
                                    <span className="ml-2">
                                        Trip Administration
                                    </span>
                                    <i className="fa fa-angle-right mm-arrow-right arrow-active"></i>
                                    <i className="fa fa-angle-down mm-arrow-right arrow-hover"></i>
                                </a>
                                <ul
                                    id="trip"
                                    className="submenu collapse"
                                    data-parent="#mm-sidebar-toggle"
                                >
                                    <li className="">
                                        <Link
                                            to="/tripoverview"
                                            className="svg-icon"
                                        >
                                            {/* <a href="triphistory.html" className="svg-icon"> */}
                                            <i className="">
                                                <svg
                                                    className="svg-icon"
                                                    id="mm-user-1"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    fill="none"
                                                    viewBox="0 0 24 24"
                                                    stroke="currentColor"
                                                >
                                                    <path
                                                        stroke-linecap="round"
                                                        stroke-linejoin="round"
                                                        stroke-width="2"
                                                        d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z"
                                                    />
                                                </svg>
                                            </i>
                                            <span className="">
                                                Trip Overview
                                            </span>
                                        </Link>
                                        {/* </a> */}
                                    </li>
                                    {/* Incident Report */}
                                    <li className="">
                                        <Link to="/report" className="svg-icon">
                                            <i className="">
                                                <svg
                                                    className="svg-icon"
                                                    id="mm-chat-1"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    fill="none"
                                                    viewBox="0 0 24 24"
                                                    stroke="currentColor"
                                                >
                                                    <path
                                                        stroke-linecap="round"
                                                        stroke-linejoin="round"
                                                        stroke-width="2"
                                                        d="M8 12h.01M12 12h.01M16 12h.01M21 12c0 4.418-4.03 8-9 8a9.863 9.863 0 01-4.255-.949L3 20l1.395-3.72C3.512 15.042 3 13.574 3 12c0-4.418 4.03-8 9-8s9 3.582 9 8z"
                                                    />
                                                </svg>
                                            </i>
                                            <span className="">
                                                Incident Report
                                            </span>
                                        </Link>
                                    </li>
                                </ul>
                            </li>
                            <li className="">
                                <a
                                    href="#ui"
                                    className="collapsed svg-icon"
                                    data-toggle="collapse"
                                    aria-expanded="false"
                                >
                                    <i>
                                        <svg
                                            className="svg-icon"
                                            id="mm-ui-1"
                                            width="20"
                                            xmlns="http://www.w3.org/2000/svg"
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            stroke="currentColor"
                                        >
                                            <path
                                                stroke-linecap="round"
                                                stroke-linejoin="round"
                                                stroke-width="2"
                                                d="M7 21a4 4 0 01-4-4V5a2 2 0 012-2h4a2 2 0 012 2v12a4 4 0 01-4 4zm0 0h12a2 2 0 002-2v-4a2 2 0 00-2-2h-2.343M11 7.343l1.657-1.657a2 2 0 012.828 0l2.829 2.829a2 2 0 010 2.828l-8.486 8.485M7 17h.01"
                                            />
                                        </svg>
                                    </i>
                                    <span className="ml-2">
                                        Partner Management
                                    </span>
                                    <i className="fa fa-angle-right mm-arrow-right arrow-active"></i>
                                    <i className="fa fa-angle-down mm-arrow-right arrow-hover"></i>
                                </a>
                                <ul
                                    id="ui"
                                    className="submenu collapse"
                                    data-parent="#mm-sidebar-toggle"
                                >
                                    <li className="">
                                        <Link
                                            to="/partner-overview"
                                            className="svg-icon"
                                        >
                                            <i className="">
                                                <svg
                                                    className="svg-icon"
                                                    id="mm-ui-1-3"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    fill="none"
                                                    viewBox="0 0 24 24"
                                                    stroke="currentColor"
                                                >
                                                    <path
                                                        stroke-linecap="round"
                                                        stroke-linejoin="round"
                                                        stroke-width="2"
                                                        d="M4 6h16M4 12h16M4 18h16"
                                                    />
                                                </svg>
                                            </i>
                                            <span className="">
                                                Partners Overview
                                            </span>
                                        </Link>
                                    </li>
                                    <li className="">
                                        <Link
                                            to="/new-partners"
                                            className="svg-icon"
                                        >
                                            <i className="">
                                                <svg
                                                    className="svg-icon"
                                                    id="mm-ui-1-1"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    fill="none"
                                                    viewBox="0 0 24 24"
                                                    stroke="currentColor"
                                                >
                                                    <path
                                                        stroke-linecap="round"
                                                        stroke-linejoin="round"
                                                        stroke-width="2"
                                                        d="M7 7h.01M7 3h5c.512 0 1.024.195 1.414.586l7 7a2 2 0 010 2.828l-7 7a2 2 0 01-2.828 0l-7-7A1.994 1.994 0 013 12V7a4 4 0 014-4z"
                                                    />
                                                </svg>
                                            </i>
                                            <span className="">
                                                New Partners
                                            </span>
                                        </Link>
                                    </li>
                                    <li className="">
                                        <Link
                                            to="/verified-partners"
                                            className="svg-icon"
                                        >
                                            <i className="">
                                                <svg
                                                    className="svg-icon"
                                                    id="mm-ui-1-1"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    fill="none"
                                                    viewBox="0 0 24 24"
                                                    stroke="currentColor"
                                                >
                                                    <path
                                                        stroke-linecap="round"
                                                        stroke-linejoin="round"
                                                        stroke-width="2"
                                                        d="M7 7h.01M7 3h5c.512 0 1.024.195 1.414.586l7 7a2 2 0 010 2.828l-7 7a2 2 0 01-2.828 0l-7-7A1.994 1.994 0 013 12V7a4 4 0 014-4z"
                                                    />
                                                </svg>
                                            </i>
                                            <span className="">
                                                Verified Partners
                                            </span>
                                        </Link>
                                    </li>
                                    <li className="">
                                        <Link
                                            to="/approved-partners"
                                            className="svg-icon"
                                        >
                                            <i className="">
                                                <svg
                                                    className="svg-icon"
                                                    id="mm-ui-1-0"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    fill="none"
                                                    viewBox="0 0 24 24"
                                                    stroke="currentColor"
                                                >
                                                    <path
                                                        stroke-linecap="round"
                                                        stroke-linejoin="round"
                                                        stroke-width="2"
                                                        d="M5.121 17.804A13.937 13.937 0 0112 16c2.5 0 4.847.655 6.879 1.804M15 10a3 3 0 11-6 0 3 3 0 016 0zm6 2a9 9 0 11-18 0 9 9 0 0118 0z"
                                                    />
                                                </svg>
                                            </i>
                                            <span className="">
                                                Approved Partners
                                            </span>
                                        </Link>
                                    </li>
                                    {/* <li className="">
<a href="unconfirmed-partner.html" className="svg-icon">
<i className="">
<svg className="svg-icon" id="mm-ui-1-2" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
<path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2m-3 7h3m-3 4h3m-6-4h.01M9 16h.01" />
</svg>
</i><span className="">Add New Partner</span>
</a>
</li>  */}
                                </ul>
                            </li>
                            <li className="">
                                <a
                                    href="#table"
                                    className="collapsed svg-icon"
                                    data-toggle="collapse"
                                    aria-expanded="false"
                                >
                                    <i>
                                        <svg
                                            className="svg-icon"
                                            id="mm-table-1"
                                            width="20"
                                            xmlns="http://www.w3.org/2000/svg"
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            stroke="currentColor"
                                        >
                                            <path
                                                stroke-linecap="round"
                                                stroke-linejoin="round"
                                                stroke-width="2"
                                                d="M4 6h16M4 10h16M4 14h16M4 18h16"
                                            />
                                        </svg>
                                    </i>
                                    <span className="ml-2">
                                        Driver Management
                                    </span>
                                    <i className="fa fa-angle-right mm-arrow-right arrow-active"></i>
                                    <i className="fa fa-angle-down mm-arrow-right arrow-hover"></i>
                                </a>
                                <ul
                                    id="table"
                                    className="submenu collapse"
                                    data-parent="#mm-sidebar-toggle"
                                >  
                                    <li className="">
                                        <Link
                                            to="/unverified-rider"
                                            className="svg-icon"
                                        >
                                            <i className="">
                                                <svg
                                                    className="svg-icon"
                                                    id="mm-table-1-2"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width="20"
                                                    viewBox="0 0 24 24"
                                                    stroke-width="1.5"
                                                    stroke="#2c3e50"
                                                    fill="none"
                                                    stroke-linecap="round"
                                                    stroke-linejoin="round"
                                                >
                                                    <path
                                                        stroke="none"
                                                        d="M0 0h24v24H0z"
                                                        fill="none"
                                                    />
                                                    <rect
                                                        x="4"
                                                        y="4"
                                                        width="16"
                                                        height="16"
                                                        rx="2"
                                                    />
                                                    <line
                                                        x1="4"
                                                        y1="10"
                                                        x2="20"
                                                        y2="10"
                                                    />
                                                    <line
                                                        x1="10"
                                                        y1="4"
                                                        x2="10"
                                                        y2="20"
                                                    />
                                                </svg>
                                            </i>
                                            <span className="">
                                                Unverified Rider
                                            </span>
                                        </Link>
                                    </li>
                                    <li className="">
                                        <Link
                                            to="/unconfirmed-rider"
                                            className="svg-icon"
                                        >
                                            <i className="">
                                                <svg
                                                    className="svg-icon"
                                                    id="mm-table-1-2"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width="20"
                                                    viewBox="0 0 24 24"
                                                    stroke-width="1.5"
                                                    stroke="#2c3e50"
                                                    fill="none"
                                                    stroke-linecap="round"
                                                    stroke-linejoin="round"
                                                >
                                                    <path
                                                        stroke="none"
                                                        d="M0 0h24v24H0z"
                                                        fill="none"
                                                    />
                                                    <rect
                                                        x="4"
                                                        y="4"
                                                        width="16"
                                                        height="16"
                                                        rx="2"
                                                    />
                                                    <line
                                                        x1="4"
                                                        y1="10"
                                                        x2="20"
                                                        y2="10"
                                                    />
                                                    <line
                                                        x1="10"
                                                        y1="4"
                                                        x2="10"
                                                        y2="20"
                                                    />
                                                </svg>
                                            </i>
                                            <span className="">
                                                Unconfirmed Rider
                                            </span>
                                        </Link>
                                    </li>
                                    <li className="">
                                        <Link
                                            to="/confirmed-rider"
                                            className="svg-icon"
                                        >
                                            <i className="">
                                                <svg
                                                    className="svg-icon"
                                                    id="mm-table-1-1"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width="20"
                                                    viewBox="0 0 24 24"
                                                    stroke-width="1.5"
                                                    stroke="#2c3e50"
                                                    fill="none"
                                                    stroke-linecap="round"
                                                    stroke-linejoin="round"
                                                >
                                                    <path
                                                        stroke="none"
                                                        d="M0 0h24v24H0z"
                                                        fill="none"
                                                    />
                                                    <rect
                                                        x="4"
                                                        y="4"
                                                        width="16"
                                                        height="16"
                                                        rx="2"
                                                    />
                                                    <line
                                                        x1="4"
                                                        y1="10"
                                                        x2="20"
                                                        y2="10"
                                                    />
                                                    <line
                                                        x1="10"
                                                        y1="4"
                                                        x2="10"
                                                        y2="20"
                                                    />
                                                </svg>
                                            </i>
                                            <span className="">
                                                Confirmed Rider
                                            </span>
                                        </Link>
                                    </li>
                                    {/* Disciplinary Case */}
                                    {/* <li className="">
                                        <Link
                                            to="/disciplinary-case"
                                            className="svg-icon"
                                        >
                                            <i className="">
                                                <svg
                                                    className="svg-icon"
                                                    id="mm-table-1-3"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width="20"
                                                    viewBox="0 0 24 24"
                                                    stroke-width="1.5"
                                                    stroke="#2c3e50"
                                                    fill="none"
                                                    stroke-linecap="round"
                                                    stroke-linejoin="round"
                                                >
                                                    <path
                                                        stroke="none"
                                                        d="M0 0h24v24H0z"
                                                        fill="none"
                                                    />
                                                    <line
                                                        x1="4"
                                                        y1="6"
                                                        x2="9.5"
                                                        y2="6"
                                                    />
                                                    <line
                                                        x1="4"
                                                        y1="10"
                                                        x2="9.5"
                                                        y2="10"
                                                    />
                                                    <line
                                                        x1="4"
                                                        y1="14"
                                                        x2="9.5"
                                                        y2="14"
                                                    />
                                                    <line
                                                        x1="4"
                                                        y1="18"
                                                        x2="9.5"
                                                        y2="18"
                                                    />
                                                    <line
                                                        x1="14.5"
                                                        y1="6"
                                                        x2="20"
                                                        y2="6"
                                                    />
                                                    <line
                                                        x1="14.5"
                                                        y1="10"
                                                        x2="20"
                                                        y2="10"
                                                    />
                                                    <line
                                                        x1="14.5"
                                                        y1="14"
                                                        x2="20"
                                                        y2="14"
                                                    />
                                                    <line
                                                        x1="14.5"
                                                        y1="18"
                                                        x2="20"
                                                        y2="18"
                                                    />
                                                </svg>
                                            </i>
                                            <span className="">
                                                Disciplinary Case
                                            </span>
                                        </Link>
                                    </li> */}
                                    {/* Disciplinary Measure */}
                                    {/* <li className="">
                                        <Link
                                            to="/disciplinary-measure"
                                            className="svg-icon"
                                        >
                                            <i className="">
                                                <svg
                                                    className="svg-icon"
                                                    id="mm-table-1-4"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width="20"
                                                    viewBox="0 0 24 24"
                                                    stroke-width="1.5"
                                                    stroke="#2c3e50"
                                                    fill="none"
                                                    stroke-linecap="round"
                                                    stroke-linejoin="round"
                                                >
                                                    <path
                                                        stroke="none"
                                                        d="M0 0h24v24H0z"
                                                        fill="none"
                                                    />
                                                    <path d="M12 3l4 4l-2 1l4 4l-3 1l4 4h-14l4 -4l-3 -1l4 -4l-2 -1z" />
                                                    <path d="M14 17v3a1 1 0 0 1 -1 1h-2a1 1 0 0 1 -1 -1v-3" />
                                                </svg>
                                            </i>
                                            <span className="">
                                                Disciplinary Measure
                                            </span>
                                        </Link>
                                    </li> */}
                                </ul>
                            </li>
                            <li className="">
                                <a
                                    href="#icon"
                                    className="collapsed svg-icon"
                                    data-toggle="collapse"
                                    aria-expanded="false"
                                >
                                    <i>
                                        <svg
                                            className="svg-icon"
                                            id="mm-icon-1"
                                            width="20"
                                            xmlns="http://www.w3.org/2000/svg"
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            stroke="currentColor"
                                        >
                                            <path
                                                stroke-linecap="round"
                                                stroke-linejoin="round"
                                                stroke-width="2"
                                                d="M19 11H5m14 0a2 2 0 012 2v6a2 2 0 01-2 2H5a2 2 0 01-2-2v-6a2 2 0 012-2m14 0V9a2 2 0 00-2-2M5 11V9a2 2 0 012-2m0 0V5a2 2 0 012-2h6a2 2 0 012 2v2M7 7h10"
                                            />
                                        </svg>
                                    </i>
                                    <span className="ml-2">
                                        Vehicle Management
                                    </span>
                                    <i className="fa fa-angle-right mm-arrow-right arrow-active"></i>
                                    <i className="fa fa-angle-down mm-arrow-right arrow-hover"></i>
                                </a>
                                <ul
                                    id="icon"
                                    className="submenu collapse"
                                    data-parent="#mm-sidebar-toggle"
                                >
                                    {/* Vehicle Overview */}
                                    {/* <li className="">
                                        <Link
                                            to="/vehicle-overview"
                                            className="svg-icon"
                                        >
                                            <i className="">
                                                <svg
                                                    className="svg-icon feather feather-layers"
                                                    id="mm-icon-1-1"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width="20"
                                                    viewBox="0 0 24 24"
                                                    fill="none"
                                                    stroke="currentColor"
                                                    stroke-width="2"
                                                    stroke-linecap="round"
                                                    stroke-linejoin="round"
                                                >
                                                    <polygon points="12 2 2 7 12 12 22 7 12 2"></polygon>
                                                    <polyline points="2 17 12 22 22 17"></polyline>
                                                    <polyline points="2 12 12 17 22 12"></polyline>
                                                </svg>
                                            </i>
                                            <span className="">
                                                Vehicle Overview
                                            </span>
                                        </Link>
                                    </li> */}
                                    <li className="">
                                        <Link
                                            to="/unverified-vehicle"
                                            className="svg-icon"
                                        >
                                            <i className="">
                                                <svg
                                                    className="svg-icon feather feather-layers"
                                                    id="mm-icon-1-1"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width="20"
                                                    viewBox="0 0 24 24"
                                                    fill="none"
                                                    stroke="currentColor"
                                                    stroke-width="2"
                                                    stroke-linecap="round"
                                                    stroke-linejoin="round"
                                                >
                                                    <polygon points="12 2 2 7 12 12 22 7 12 2"></polygon>
                                                    <polyline points="2 17 12 22 22 17"></polyline>
                                                    <polyline points="2 12 12 17 22 12"></polyline>
                                                </svg>
                                            </i>
                                            <span className="">
                                                Unverified Vehicle
                                            </span>
                                        </Link>
                                    </li>
                                    <li className="">
                                        <Link
                                            to="/unconfirmed-vehicle"
                                            className="svg-icon"
                                        >
                                            <i className="">
                                                <svg
                                                    className="svg-icon feather feather-facebook"
                                                    id="mm-icon-1-2"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width="20"
                                                    viewBox="0 0 24 24"
                                                    fill="none"
                                                    stroke="currentColor"
                                                    stroke-width="2"
                                                    stroke-linecap="round"
                                                    stroke-linejoin="round"
                                                >
                                                    <path d="M18 2h-3a5 5 0 0 0-5 5v3H7v4h3v8h4v-8h3l1-4h-4V7a1 1 0 0 1 1-1h3z"></path>
                                                </svg>
                                            </i>
                                            <span className="">
                                                Unconfirmed Vehicle
                                            </span>
                                        </Link>
                                    </li>
                                    <li className="">
                                        <Link
                                            to="/confirmed-vehicle"
                                            className="svg-icon"
                                        >
                                            <i className="">
                                                <svg
                                                    className="svg-icon feather feather-layers"
                                                    id="mm-icon-1-1"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width="20"
                                                    viewBox="0 0 24 24"
                                                    fill="none"
                                                    stroke="currentColor"
                                                    stroke-width="2"
                                                    stroke-linecap="round"
                                                    stroke-linejoin="round"
                                                >
                                                    <polygon points="12 2 2 7 12 12 22 7 12 2"></polygon>
                                                    <polyline points="2 17 12 22 22 17"></polyline>
                                                    <polyline points="2 12 12 17 22 12"></polyline>
                                                </svg>
                                            </i>
                                            <span className="">
                                                Confirmed Vehicle
                                            </span>
                                        </Link>
                                    </li>
                                </ul>
                            </li>
                            <li className="">
                                <a
                                    href="#form"
                                    className="collapsed svg-icon"
                                    data-toggle="collapse"
                                    aria-expanded="false"
                                >
                                    <i>
                                        <svg
                                            className="svg-icon"
                                            id="mm-form-1"
                                            width="20"
                                            xmlns="http://www.w3.org/2000/svg"
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            stroke="currentColor"
                                        >
                                            <path
                                                stroke-linecap="round"
                                                stroke-linejoin="round"
                                                stroke-width="2"
                                                d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2m-3 7h3m-3 4h3m-6-4h.01M9 16h.01"
                                            />
                                        </svg>
                                    </i>
                                    <span className="ml-2">
                                        Finance Management
                                    </span>
                                    <i className="fa fa-angle-right mm-arrow-right arrow-active"></i>
                                    <i className="fa fa-angle-down mm-arrow-right arrow-hover"></i>
                                </a>
                                <ul
                                    id="form"
                                    className="submenu collapse"
                                    data-parent="#mm-sidebar-toggle"
                                >
                                    <li className="">
                                        <Link
                                            to="/incomes"
                                            className="svg-icon"
                                        >
                                            <i className="">
                                                <svg
                                                    className="svg-icon"
                                                    id="mm-form-1-1"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    fill="none"
                                                    viewBox="0 0 24 24"
                                                    stroke="currentColor"
                                                >
                                                    <path
                                                        stroke-linecap="round"
                                                        stroke-linejoin="round"
                                                        stroke-width="2"
                                                        d="M12 18h.01M7 21h10a2 2 0 002-2V5a2 2 0 00-2-2H7a2 2 0 00-2 2v14a2 2 0 002 2z"
                                                    />
                                                </svg>
                                            </i>
                                            <span className="">Incomes</span>
                                        </Link>
                                    </li>
                                    {/* Settlement */}
                                    <li className="">
                                        <Link
                                        to="/Settlement"
                                        className="svg-icon">
                                            <i className="">
                                                <svg
                                                className="svg-icon"
                                                id="mm-form-2"
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="20"
                                                viewBox="0 0 24 24"
                                                stroke-width="1.5"
                                                stroke="#2c3e50"
                                                fill="none"
                                                stroke-linecap="round"
                                                stroke-linejoin="round"
                                                >
                                                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                                    <rect x="5" y="3" width="14" height="18" rx="2" />
                                                    <line x1="9" y1="7" x2="15" y2="7" />
                                                    <line x1="9" y1="11" x2="15" y2="11" />
                                                    <line x1="9" y1="15" x2="13" y2="15" />
                                                </svg>
                                            </i>
                                            <span className="">Settlement</span>
                                        </Link>
                                    </li>
                                    {/* Wallet */}
                                    <li className="">
                                        <Link
                                            to="/wallet"
                                            className="svg-icon"
                                        >
                                            <i className="">
                                                <svg
                                                    className="svg-icon"
                                                    id="mm-form-1-1"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    fill="none"
                                                    viewBox="0 0 24 24"
                                                    stroke="currentColor"
                                                >
                                                    <path
                                                        stroke-linecap="round"
                                                        stroke-linejoin="round"
                                                        stroke-width="2"
                                                        d="M12 18h.01M7 21h10a2 2 0 002-2V5a2 2 0 00-2-2H7a2 2 0 00-2 2v14a2 2 0 002 2z"
                                                    />
                                                </svg>
                                            </i>
                                            <span className="">Wallet</span>
                                        </Link>
                                    </li>
                                </ul>
                            </li>
                            <li className="">
                                <a
                                    href="#pickUp"
                                    className="collapsed svg-icon"
                                    data-toggle="collapse"
                                    aria-expanded="false"
                                >
                                    <i>
                                        <svg
                                            className="svg-icon"
                                            id="mm-app-1"
                                            width="20"
                                            xmlns="http://www.w3.org/2000/svg"
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            stroke="currentColor"
                                        >
                                            <path
                                                stroke-linecap="round"
                                                stroke-linejoin="round"
                                                stroke-width="2"
                                                d="M4 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2V6zM14 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2V6zM4 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2v-2zM14 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2v-2z"
                                            />
                                        </svg>
                                    </i>
                                    <span className="ml-2">
                                        Pick Up Management
                                    </span>
                                    <i className="fa fa-angle-right mm-arrow-right arrow-active"></i>
                                    <i className="fa fa-angle-down mm-arrow-right arrow-hover"></i>
                                </a>
                                <ul
                                    id="pickUp"
                                    className="submenu collapse"
                                    data-parent="#mm-sidebar-toggle"
                                >
                                    <li className="">
                                        <Link
                                            to="/pickUp"
                                            className="svg-icon"
                                        >
                                            {/* <a href="order-overview.html" className="svg-icon"> */}
                                            <i className="">
                                                <svg
                                                    className="svg-icon"
                                                    id="mm-ui-1-1"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    fill="none"
                                                    viewBox="0 0 24 24"
                                                    stroke="currentColor"
                                                >
                                                    <path
                                                        stroke-linecap="round"
                                                        stroke-linejoin="round"
                                                        stroke-width="2"
                                                        d="M7 7h.01M7 3h5c.512 0 1.024.195 1.414.586l7 7a2 2 0 010 2.828l-7 7a2 2 0 01-2.828 0l-7-7A1.994 1.994 0 013 12V7a4 4 0 014-4z"
                                                    />
                                                </svg>
                                            </i>
                                            <span className="">
                                                Pick a Bag
                                            </span>
                                        </Link>
                                        {/* </a> */}
                                    </li>
                                </ul>
                            </li>
                            <li className="">
                                <a
                                    href="#pages"
                                    className="collapsed svg-icon"
                                    data-toggle="collapse"
                                    aria-expanded="false"
                                >
                                    <i>
                                        <svg
                                            className="svg-icon"
                                            id="mm-extra-1"
                                            width="20"
                                            xmlns="http://www.w3.org/2000/svg"
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            stroke="currentColor"
                                        >
                                            <path
                                                stroke-linecap="round"
                                                stroke-linejoin="round"
                                                stroke-width="2"
                                                d="M5 12h14M5 12a2 2 0 01-2-2V6a2 2 0 012-2h14a2 2 0 012 2v4a2 2 0 01-2 2M5 12a2 2 0 00-2 2v4a2 2 0 002 2h14a2 2 0 002-2v-4a2 2 0 00-2-2m-2-4h.01M17 16h.01"
                                            />
                                        </svg>
                                    </i>
                                    <span className="ml-2">Settings</span>
                                    <i className="fa fa-angle-right mm-arrow-right arrow-active"></i>
                                    <i className="fa fa-angle-down mm-arrow-right arrow-hover"></i>
                                </a>
                                <ul
                                    id="pages"
                                    className="submenu collapse"
                                    data-parent="#mm-sidebar-toggle"
                                >
                                    <li className="">
                                        <Link
                                            to="/categories"
                                            className="svg-icon"
                                        >
                                            <i className="">
                                                <svg
                                                    className="svg-icon"
                                                    id="mm-extra-1-2"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width="20"
                                                    viewBox="0 0 24 24"
                                                    stroke-width="1.5"
                                                    stroke="#2c3e50"
                                                    fill="none"
                                                    stroke-linecap="round"
                                                    stroke-linejoin="round"
                                                >
                                                    <path
                                                        stroke="none"
                                                        d="M0 0h24v24H0z"
                                                        fill="none"
                                                    />
                                                    <line
                                                        x1="12"
                                                        y1="12"
                                                        x2="12"
                                                        y2="12.01"
                                                    />
                                                    <path
                                                        d="M12 2a4 10 0 0 0 -4 10a4 10 0 0 0 4 10a4 10 0 0 0 4 -10a4 10 0 0 0 -4 -10"
                                                        transform="rotate(45 12 12)"
                                                    />
                                                    <path
                                                        d="M12 2a4 10 0 0 0 -4 10a4 10 0 0 0 4 10a4 10 0 0 0 4 -10a4 10 0 0 0 -4 -10"
                                                        transform="rotate(-45 12 12)"
                                                    />
                                                </svg>
                                            </i>
                                            <span className="">Categories</span>
                                        </Link>
                                    </li>
                                    {/* Price Management */}
                                    {/* <li className="">
                                        <Link
                                            to="/price-management"
                                            className="svg-icon"
                                        >
                                            <i className="">
                                                <svg
                                                    className="svg-icon feather feather-alert-triangle"
                                                    id="mm-extra-1-4"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width="20"
                                                    viewBox="0 0 24 24"
                                                    fill="none"
                                                    stroke="currentColor"
                                                    stroke-width="2"
                                                    stroke-linecap="round"
                                                    stroke-linejoin="round"
                                                >
                                                    <path d="M10.29 3.86L1.82 18a2 2 0 0 0 1.71 3h16.94a2 2 0 0 0 1.71-3L13.71 3.86a2 2 0 0 0-3.42 0z"></path>
                                                    <line
                                                        x1="12"
                                                        y1="9"
                                                        x2="12"
                                                        y2="13"
                                                    ></line>
                                                    <line
                                                        x1="12"
                                                        y1="17"
                                                        x2="12.01"
                                                        y2="17"
                                                    ></line>
                                                </svg>
                                            </i>
                                            <span className="">
                                                Price Management
                                            </span>
                                        </Link>
                                    </li> */}
                                    {/* Role */}
                                    {/* <li className="">
                                        <Link
                                        className="svg-icon"
                                        to="/userRoles"
                                        >
                                            <i className="">
                                                <svg
                                                className="svg-icon"
                                                id="mm-extra-1-3-1"
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="20"
                                                viewBox="0 0 24 24"
                                                stroke-width="1.5"
                                                stroke="#2c3e50"
                                                fill="none"
                                                stroke-linecap="round"
                                                stroke-linejoin="round"
                                                >
                                                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                                    <rect x="4" y="4" width="16" height="16" rx="2" />
                                                </svg>
                                            </i>
                                            <span className="">User Roles</span>
                                        </Link>
                                    </li>  */}
                                    {/* Permission */}
                                    {/* <li className="">
                    <a href="pages-invoice.html" className="svg-icon">
                      <i className="">
                        <svg
                          className="svg-icon"
                          id="mm-extra-1-5"
                          xmlns="http://www.w3.org/2000/svg"
                          width="20"
                          viewBox="0 0 24 24"
                          stroke-width="1.5"
                          stroke="#2c3e50"
                          fill="none"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        >
                          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                          <path d="M14 3v4a1 1 0 0 0 1 1h4" />
                          <path d="M17 21h-10a2 2 0 0 1 -2 -2v-14a2 2 0 0 1 2 -2h7l5 5v11a2 2 0 0 1 -2 2z" />
                          <line x1="9" y1="9" x2="10" y2="9" />
                          <line x1="9" y1="13" x2="15" y2="13" />
                          <line x1="9" y1="17" x2="15" y2="17" />
                        </svg>
                      </i>
                      <span className="">Permissions</span>
                    </a>
                  </li> */}
                                    <li className="">
                                        <Link
                                            to="/vehicle-settings"
                                            className="svg-icon"
                                        >
                                            <i className="">
                                                <svg
                                                    className="svg-icon"
                                                    id="mm-extra-1-7"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width="20"
                                                    viewBox="0 0 24 24"
                                                    stroke-width="1.5"
                                                    stroke="#2c3e50"
                                                    fill="none"
                                                    stroke-linecap="round"
                                                    stroke-linejoin="round"
                                                >
                                                    <path
                                                        stroke="none"
                                                        d="M0 0h24v24H0z"
                                                        fill="none"
                                                    />
                                                    <circle
                                                        cx="12"
                                                        cy="12"
                                                        r="9"
                                                    />
                                                    <path d="M8 16l1.106 -1.99m1.4 -2.522l2.494 -4.488" />
                                                    <path d="M7 14h5m2.9 0h2.1" />
                                                    <path d="M16 16l-2.51 -4.518m-1.487 -2.677l-1.003 -1.805" />
                                                </svg>
                                            </i>
                                            <span className="">Vehicles</span>
                                        </Link>
                                    </li>
                                    <li className="">
                                        <Link
                                            to="/key-settings"
                                            className="svg-icon"
                                        >
                                            <i className="">
                                                <svg
                                                    className="svg-icon"
                                                    id="mm-extra-1-7"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width="20"
                                                    viewBox="0 0 24 24"
                                                    stroke-width="1.5"
                                                    stroke="#2c3e50"
                                                    fill="none"
                                                    stroke-linecap="round"
                                                    stroke-linejoin="round"
                                                >
                                                    <path
                                                        stroke="none"
                                                        d="M0 0h24v24H0z"
                                                        fill="none"
                                                    />
                                                    <circle
                                                        cx="12"
                                                        cy="12"
                                                        r="9"
                                                    />
                                                    <path d="M8 16l1.106 -1.99m1.4 -2.522l2.494 -4.488" />
                                                    <path d="M7 14h5m2.9 0h2.1" />
                                                    <path d="M16 16l-2.51 -4.518m-1.487 -2.677l-1.003 -1.805" />
                                                </svg>
                                            </i>
                                            <span className="">Keys</span>
                                        </Link>
                                    </li>
                                    <li className="">
                                        <Link
                                            to="/rating-questions"
                                            className="svg-icon"
                                        >
                                            <i className="">
                                                <svg
                                                    className="svg-icon feather feather-alert-triangle"
                                                    id="mm-extra-1-4"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width="20"
                                                    viewBox="0 0 24 24"
                                                    fill="none"
                                                    stroke="currentColor"
                                                    stroke-width="2"
                                                    stroke-linecap="round"
                                                    stroke-linejoin="round"
                                                >
                                                    <path d="M10.29 3.86L1.82 18a2 2 0 0 0 1.71 3h16.94a2 2 0 0 0 1.71-3L13.71 3.86a2 2 0 0 0-3.42 0z"></path>
                                                    <line
                                                        x1="12"
                                                        y1="9"
                                                        x2="12"
                                                        y2="13"
                                                    ></line>
                                                    <line
                                                        x1="12"
                                                        y1="17"
                                                        x2="12.01"
                                                        y2="17"
                                                    ></line>
                                                </svg>
                                            </i>
                                            <span className="">
                                                Rating Questions
                                            </span>
                                        </Link>
                                    </li>
                                    <li className="">
                                        <Link
                                            to="/change-password"
                                            className="svg-icon"
                                        >
                                            <i className="">
                                                <svg
                                                    className="svg-icon"
                                                    id="mm-extra-1-7"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width="20"
                                                    viewBox="0 0 24 24"
                                                    stroke-width="1.5"
                                                    stroke="#2c3e50"
                                                    fill="none"
                                                    stroke-linecap="round"
                                                    stroke-linejoin="round"
                                                >
                                                    <path
                                                        stroke="none"
                                                        d="M0 0h24v24H0z"
                                                        fill="none"
                                                    />
                                                    <circle
                                                        cx="12"
                                                        cy="12"
                                                        r="9"
                                                    />
                                                    <path d="M8 16l1.106 -1.99m1.4 -2.522l2.494 -4.488" />
                                                    <path d="M7 14h5m2.9 0h2.1" />
                                                    <path d="M16 16l-2.51 -4.518m-1.487 -2.677l-1.003 -1.805" />
                                                </svg>
                                            </i>
                                            <span className="">
                                                Change Password
                                            </span>
                                        </Link>
                                    </li>
                                </ul>
                                <ul>
                                    <li className="">
                                        <a
                                            href="/signin"
                                            className="svg-icon"
                                            onClick={handleSignout}
                                        >
                                            {/* <a href="index.html" className="svg-icon"> */}
                                            <i className="">
                                                <i className="fa-solid fa-power-off"></i>
                                            </i>
                                            <span className="ml-2">Logout</span>
                                        </a>
                                        {/* </a> */}
                                    </li>
                                </ul>
                            </li>
                        </ul>
                    </nav>
                    <div className="pt-5 pb-2"></div>
                </div>
            </div>
        </>
    );
};

export default SideBar;
