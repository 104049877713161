import axios from "axios";
import axiosInstance from "../../utils/axios";
import fetchData, { fetchData2 } from "../../utils/fetchData";
import { orderConstants } from "./constants";
import { api } from "../../urlConfig";
import { axiosConfig } from "../../utils/axios";
import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

const baseURL = api

export const getOrders = () => {
    return async (dispatch) => {
        dispatch({ type: orderConstants.GET_ORDERS_REQUEST });
        const res = await axiosInstance.get("/Admin/orders");
        // console.log("res", res);
        // const res = await axiosInstance("http://localhost:4000/orders");
        // const res = await fetchData2("/orders");

        if (res.status === 200) {
            const { data } = res;
            dispatch({
                type: orderConstants.GET_ORDERS_SUCCESS,
                payload: { allOrders: data },
            });
        } else {
        }
    };
};
export const getManualOrders = () => {
    return async (dispatch) => {
        dispatch({ type: orderConstants.GET_ORDERS_REQUEST });
        const res = await axiosInstance.get("/Admin/manualorders");
        // console.log("res", res);
        // const res = await axiosInstance("http://localhost:4000/orders");
        // const res = await fetchData2("/orders");

        if (res.status === 200) {
            const { data } = res;
            dispatch({
                type: orderConstants.GET_ORDERS_SUCCESS,
                payload: { allOrders: data },
            });
        } else {
        }
    };
};

export const getOrderReportByMonth = () => {
    return async (dispatch) => {
        dispatch({ type: orderConstants.GET_ORDER_REPORT_BY_MONTH_REQUEST });
        const res = await axiosInstance.get("/Admin/orders/reportbymonth");
        // const res = await axios.get("http://localhost:4000/reportByMonth");

        // console.log("res", res);
        if (res.status === 200) {
            const { data } = res;
            dispatch({
                type: orderConstants.GET_ORDER_REPORT_BY_MONTH_SUCCESS,
                payload: { reportByMonth: data },
            });
        }
    };
};

export const downloadOrderReport = (req) => {
    return async (dispatch) => {
        dispatch({ type: orderConstants.EXPORT_COD_REQUEST });
        const res = await axiosInstance.get(`/Admin/orders/exportorders?id=${req.id}&start=${req.start}&end=${req.end}`, {responseType: 'blob'});

        console.log("res", res);
        if (res.status === 200) {
            dispatch({
                type: orderConstants.EXPORT_COD_SUCCESS,
            });
            let url = window.URL.createObjectURL(res.data);
            let a = document.createElement('a');
            a.href = url;
            a.download = `OrderReportfor${req.start}-${req.end}.xlsx`;
            a.click();
        }
    };
};

export const getOrderReportByYear = () => {
    return async (dispatch) => {
        dispatch({ type: orderConstants.GET_ORDER_REPORT_BY_YEAR_REQUEST });
        const res = await axiosInstance.get("/Admin/orders/reportbyyear");
        // const res = await axios.get("http://localhost:4000/reportByYear");

        // console.log("res", res);
        if (res.status === 200) {
            const { data } = res;
            dispatch({
                type: orderConstants.GET_ORDER_REPORT_BY_YEAR_SUCCESS,
                payload: { reportByYear: data },
            });
        }
    };
};

export const getOrderReportByStatus = () => {
    return async (dispatch) => {
        dispatch({ type: orderConstants.GET_ORDER_REPORT_BY_STATUS_REQUEST });
        const res = await axiosInstance.get("/Admin/orders/reportbystatus");
        // const res = await axios.get("http://localhost:4000/reportByStatus");

        console.log("res", res);
        if (res.status === 200) {
            const { data } = res;
            dispatch({
                type: orderConstants.GET_ORDER_REPORT_BY_STATUS_SUCCESS,
                payload: { reportByStatus: data },
            });
        }
    };
};

export const getCODReport = (req) => {
    return async (dispatch) => {
        dispatch({ type: orderConstants.GET_COD_REPORT_REQUEST });
        const res = await axiosInstance.get(`/Order/CODReport?firstName=${req.firstName}&startDate=${req.startDate}&endDate=${req.endDate}`);

        console.log("res", res);
        if (res.status === 200) {
            const { data } = res;
            dispatch({
                type: orderConstants.GET_COD_REPORT_SUCCESS,
                payload: { codReport: data },
            });
        }
    };
};

export const getDCODReport = (req) => {
    return async (dispatch) => {
        dispatch({ type: orderConstants.GET_COD_REPORT_REQUEST });
        const res = await axiosInstance.get(`/Order/DeliveredCODReport?firstName=${req.firstName}&startDate=${req.startDate}&endDate=${req.endDate}`);

        console.log("res", res);
        if (res.status === 200) {
            const { data } = res;
            dispatch({
                type: orderConstants.GET_COD_REPORT_SUCCESS,
                payload: { codReport: data },
            });
        }
    };
};

export const downloadCODReport = (req) => {
    return async (dispatch) => {
        dispatch({ type: orderConstants.EXPORT_COD_REQUEST });
        const res = await axiosInstance.get(`/Admin/ExportCod?firstName=${req.firstName}&startDate=${req.startDate}&endDate=${req.endDate}`, {responseType: 'blob'});

        console.log("res", res);
        if (res.status === 200) {
            dispatch({
                type: orderConstants.EXPORT_COD_SUCCESS,
            });
            let url = window.URL.createObjectURL(res.data);
            let a = document.createElement('a');
            a.href = url;
            a.download = 'CashonDelivery.xlsx';
            a.click();
        }
    };
};

export const downloadDCODReport = (req) => {
    return async (dispatch) => {
        dispatch({ type: orderConstants.EXPORT_COD_REQUEST });
        const res = await axiosInstance.get(`/Admin/ExportDeliveredCod?firstName=${req.firstName}&startDate=${req.startDate}&endDate=${req.endDate}`, {responseType: 'blob'});

        console.log("res", res);
        if (res.status === 200) {
            dispatch({
                type: orderConstants.EXPORT_COD_SUCCESS,
            });
            let url = window.URL.createObjectURL(res.data);
            let a = document.createElement('a');
            a.href = url;
            a.download = 'CashonDelivery.xlsx';
            a.click();
        }
    };
};

export const cancelOrder = (id) => {
    return async (dispatch) => {
        dispatch({ type: orderConstants.CANCEL_ORDER_REQUEST });
        const res = await axiosInstance.get(`/Admin/cancelOrder/${id}`);
        console.log("res", res);
        if (res.status === 200) {
            dispatch({
                type: orderConstants.CANCEL_ORDER_SUCCESS,
            });
            toast.success("Order has been cancelled", {
                position: "bottom-center",
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
            });
            getOrders()
        }
    };
};

export const resetOrder = (id) => {
    return async (dispatch) => {
        dispatch({ type: orderConstants.RESET_ORDER_REQUEST });
        const res = await axiosInstance.get(`/Admin/resetOrder/${id}`);
        console.log("res", res);
        if (res.status === 200) {
            dispatch({
                type: orderConstants.RESET_ORDER_SUCCESS,
            });
            toast.success("Order has been reseted", {
                position: "bottom-center",
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
            });
            getOrders()
        }
    };
};

export const downloadTemplate = () => {
    return async (dispatch) => {
        dispatch({ type: orderConstants.GET_TEMPLATE_REQUEST });
        const res = await axios.get(
          `${baseURL}/Order/DownloadBulkScheduleTemplate`, {responseType: 'blob', headers: axiosConfig.headers}
        );

        if (res.status === 200) {
            const { data } = res;
            dispatch({
                type: orderConstants.GET_TEMPLATE_SUCCESS,
                payload: { fileData: data }
            });
            let url = window.URL.createObjectURL(res.data);
            let a = document.createElement('a');
            a.href = url;
            a.download = 'Order-Template.xlsx';
            a.click();
        } else {
        }
    };
};

export const importShipment = async (id, data) => {
    return axiosInstance.post(`/Order/ImportShipment?Id=${id}`, data);
};

export const getShipments = (req) => {
    return async (dispatch) => {
        dispatch({ type: orderConstants.GET_SHIPMENTS_REQUEST });
        const res = await axiosInstance.get(`/Order/dailyshipment/${req.id}?StartDate=${req.start}&EndDate=${req.end}`);
        // console.log("res", res);
        // const res = await axiosInstance("http://localhost:4000/orders");
        // const res = await fetchData2("/orders");

        if (res.status === 200) {
            const { data } = res;
            console.log("shipData", data)
            dispatch({
                type: orderConstants.GET_SHIPMENTS_SUCCESS,
                payload: { shipments: data.shipments },
            });
            return data
        } else {
            dispatch({
                type: orderConstants.GET_SHIPMENTS_FAILURE,
            });
        }
    };
};

export const createRcs = (data) => {
    return async (dispatch) => {
        dispatch({ type: orderConstants.GENERATE_RCS_REQUEST });
        const res = await axiosInstance.post("/Order/generateRCS", data);
        // console.log("res", res);
        // const res = await axiosInstance("http://localhost:4000/orders");
        // const res = await fetchData2("/orders");

        if (res.status === 200) {
            const { data } = res;
            dispatch({
                type: orderConstants.GENERATE_RCS_SUCCESS
            });
            return data
        } else {
            dispatch({
                type: orderConstants.GENERATE_RCS_FAILURE
            });
            return {
              error: 'An error occured while generating RCS'
            }
        }
    };
};

export const createDropOffBag = (data) => {
    return async (dispatch) => {
        dispatch({ type: orderConstants.GENERATE_RCS_REQUEST });
        const res = await axiosInstance.post("/Order/generateDropOff", data);
        // console.log("res", res);
        // const res = await axiosInstance("http://localhost:4000/orders");
        // const res = await fetchData2("/orders");

        if (res.status === 200) {
            const { data } = res;
            dispatch({
                type: orderConstants.GENERATE_RCS_SUCCESS
            });
            return data
        } else {
            dispatch({
                type: orderConstants.GENERATE_RCS_FAILURE
            });
            return {
              error: 'An error occured while generating RCS'
            }
        }
    };
};

export const getRcs = (id) => {
    return async (dispatch) => {
        dispatch({ type: orderConstants.GET_RCS_REQUEST });
        const res = await axiosInstance.get(`/Order/rcs/${id}`);
        // console.log("res", res);
        // const res = await axiosInstance("http://localhost:4000/orders");
        // const res = await fetchData2("/orders");

        if (res.status === 200) {
            const { data } = res;
            dispatch({
                type: orderConstants.GET_RCS_SUCCESS,
                payload: { rcs: data },
            });
            return data
        } else {
            dispatch({
                type: orderConstants.GET_RCS_FAILURE,
            });
        }
    };
};

export const getRcsDetail = (id) => {
    return async (dispatch) => {
        dispatch({ type: orderConstants.GET_RCS_DETAIL_REQUEST });
        const res = await axiosInstance.get(`/Order/rcs/detail/${id}`);
        // console.log("res", res);
        // const res = await axiosInstance("http://localhost:4000/orders");
        // const res = await fetchData2("/orders");

        if (res.status === 200) {
            const { data } = res;
            dispatch({
                type: orderConstants.GET_RCS_DETAIL_SUCCESS,
                payload: { rcsDetail: data },
            });
            return data
        } else {
            dispatch({
                type: orderConstants.GET_RCS_DETAIL_FAILURE
            });
        }
    };
};

export const confirmRcs = (id) => {
    return async (dispatch) => {
        dispatch({ type: orderConstants.CONFIRM_HANDOVER_REQUEST });
        const res = await axiosInstance.get(`/Order/confirmRCS?rcsId=${id}`);

        if (res.status === 200) {
            const { data } = res;
            dispatch({
                type: orderConstants.CONFIRM_HANDOVER_SUCCESS
            });
            return data
        } else {
            dispatch({
                type: orderConstants.CONFIRM_HANDOVER_FAILURE
            });
            return {
              error: 'An error occured while confirming RCS'
            }
        }
    };
};

export const getCities = () => {
    return async (dispatch) => {
        dispatch({
            type: orderConstants.GET_CITIES_REQUEST,
        });
        const res = await axiosInstance.get("/Settings/cities");

        if (res.status === 200) {
            const { data } = res;
            dispatch({
                type: orderConstants.GET_CITIES_SUCCESS,
                payload: { cities: data },
            });
            return data;
        } else {
        }
    };
};

export const getDrivers = () => {
    return async (dispatch) => {
        dispatch({
            type: orderConstants.GET_DRIVERS_REQUEST,
        });
        const res = await axiosInstance.get("/Order/lms/drivers");

        if (res.status === 200) {
            const { data } = res;
            dispatch({
                type: orderConstants.GET_DRIVERS_SUCCESS,
                payload: { drivers: data },
            });
            return data;
        } else {
        }
    };
};
