import axiosInstance from "../../utils/axios";
import axios from "axios";
import {
    approvedRidersConstants,
    newRidersConstants,
    verifiedRidersConstants,
    verifyRiderConstants,
    approveRiderConstants,
    importsConstants,
    customerConstants,
    exportCustomerConstants,
    driversConstants
} from "./constants";
import { api } from "../../urlConfig";
import { axiosConfig } from "../../utils/axios";
import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

const baseURL = api

export const getNewRiders = () => {
    return async (dispatch) => {
        dispatch({
            type: newRidersConstants.GET_NEW_RIDERS_REQUEST,
        });
        const res = await axiosInstance.get("/Admin/rider/new");
        // console.log("res", res)
        if (res.status === 200) {
            const { data } = res;
            dispatch({
                type: newRidersConstants.GET_NEW_RIDERS_SUCCESS,
                payload: { newRiders: data },
            });
        } else {
        }
    };
};

export const getVerifiedRiders = () => {
    return async (dispatch) => {
        dispatch({
            type: verifiedRidersConstants.GET_VERIFIED_RIDERS_REQUEST,
        });
        const res = await axiosInstance.get("/Admin/rider/verified");

        if (res.status === 200) {
            const { data } = res;
            dispatch({
                type: verifiedRidersConstants.GET_VERIFIED_RIDERS_SUCCESS,
                payload: { verifiedRiders: data },
            });
        } else {
        }
    };
};

export const getApprovedRiders = () => {
    return async (dispatch) => {
        dispatch({
            type: approvedRidersConstants.GET_APPROVED_RIDERS_REQUEST,
        });
        const res = await axiosInstance.get("/Admin/Rider/approved");

        if (res.status === 200) {
            const { data } = res;
            dispatch({
                type: approvedRidersConstants.GET_APPROVED_RIDERS_SUCCESS,
                payload: { approvedRiders: data },
            });
        } else {
        }
    };
};

export const getCustomers = () => {
    return async (dispatch) => {
        dispatch({
            type: customerConstants.GET_CUSTOMERS_REQUEST,
        });
        const res = await axiosInstance.get("/Admin/Customers");

        if (res.status === 200) {
            const { data } = res;
            dispatch({
                type: customerConstants.GET_CUSTOMERS_SUCCESS,
                payload: { customers: data },
            });
            return data;
        } else {
        }
    };
};

export const verifyRider = (id) => {
    return async (dispatch) => {
        dispatch({
            type: verifyRiderConstants.VERIFY_RIDER_REQUEST,
        });
        const res = await axiosInstance.get(`/Admin/VerifyRider/${id}`);
        console.log("res", res);

        if (res.status === 200) {
            dispatch({
                type: verifyRiderConstants.VERIFY_RIDER_SUCCESS,
            });
            toast.success("Rider verified successfully", {
                position: "bottom-center",
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
            });
            getVerifiedRiders();
        }
    };
};

async function getdownloadRow(fileName, filePath) {
    const response = await axios.get(
      `${baseURL}/Admin/DownloadRiderTemplate`, {responseType: 'blob', headers: axiosConfig.headers});
    return response;
  }
  
  export const getRiderTemplate = () => {
      getdownloadRow().then((res) => {
        console.log('res:', res.data)
        let url = window.URL.createObjectURL(res.data);
        let a = document.createElement('a');
        a.href = url;
        a.download = 'RiderTemplate.xlsx';
        a.click();
      });
    }

export const importRider = (id, file) => {
    return async (dispatch) => {
        dispatch({
            type: importsConstants.IMPORT_RIDER_REQUEST,
        });
        const formData = new FormData()
        formData.append('PartnerId', id)
        formData.append('File', file)
        const res = await axiosInstance.post(`/Admin/ImportRider`, formData);
        console.log("res", res);

        if (res.status === 200) {
            dispatch({
                type: importsConstants.IMPORT_RIDER_SUCCESS,
            });
            toast.success("Riders Information Uploaded", {
                position: "bottom-center",
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
            });
            getNewRiders();
        } else {
            dispatch({
                type: importsConstants.IMPORT_RIDER_ERROR,
            });
            toast.error(res.data.title, {
                position: "bottom-center",
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
            });
        }
    };
};

export const importCustomer = (file) => {
    return async (dispatch) => {
        dispatch({
            type: importsConstants.IMPORT_RIDER_REQUEST,
        });
        const formData = new FormData()
        formData.append('File', file)
        const res = await axiosInstance.post(`/Account/ImportCustomer`, formData);
        console.log("res", res);

        if (res.status === 200) {
            dispatch({
                type: importsConstants.IMPORT_RIDER_SUCCESS,
            });
            toast.success("Customer Information Uploaded", {
                position: "bottom-center",
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
            });
            getCustomers();
        } else {
            dispatch({
                type: importsConstants.IMPORT_RIDER_ERROR,
            });
            toast.error(res.data.title, {
                position: "bottom-center",
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
            });
        }
    };
};
export const downloadCustomer = () => {
    return async (dispatch) => {
        dispatch({ type: exportCustomerConstants.EXPORT_CUSTOMERS_REQUEST });
        const res = await axiosInstance.get(`/Admin/ExportCustomers`, {responseType: 'blob'});

        console.log("res", res);
        if (res.status === 200) {
            dispatch({
                type: exportCustomerConstants.EXPORT_CUSTOMERS_SUCCESS,
            });
            let url = window.URL.createObjectURL(res.data);
            let a = document.createElement('a');
            a.href = url;
            a.download = 'Customers.xlsx';
            a.click();
        }
    };
};

export const approveRider = (id) => {
    return async (dispatch) => {
        dispatch({
            type: approveRiderConstants.APPROVE_RIDER_REQUEST,
        });
        const res = await axiosInstance.get(`/Admin/ApproveRider/${id}`);
        console.log("res", res);

        if (res.status === 200) {
            dispatch({
                type: approveRiderConstants.APPROVE_RIDER_SUCCESS,
            });
            toast.success("Rider approved successfully", {
                position: "bottom-center",
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
            });
            getApprovedRiders();
        }
    };
};

export const getDrivers = () => {
    return async (dispatch) => {
        dispatch({
            type: driversConstants.GET_DRIVERS_REQUEST,
        });
        const res = await axiosInstance.get("/Admin/lms/drivers");

        if (res.status === 200) {
            const { data } = res;
            dispatch({
                type: driversConstants.GET_DRIVERS_SUCCESS,
            });
            return data;
        } else {
        }
    };
};

export const postRider = (req) => {
    return async (dispatch) => {
        dispatch({
            type: driversConstants.POST_RIDER_REQUEST,
        });
        const res = await axiosInstance.post(`/Admin/PostPickRider`, req);
        console.log("res", res);

        if (res.status === 200) {
            dispatch({
                type: driversConstants.POST_RIDER_SUCCESS,
            });
            toast.success("Rider assigned successfully", {
                position: "bottom-center",
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
            });
        }
    };
};

export const postTopUp = (req) => {
    return async (dispatch) => {
        dispatch({
            type: driversConstants.POST_TOPUP_REQUEST,
        });
        const res = await axiosInstance.put(`/Admin/ManualTopUp`, req);
        console.log("res", res);

        if (res.status === 200) {
            dispatch({
                type: driversConstants.POST_TOPUP_SUCCESS,
            });
            toast.success("Wallet top up successfully", {
                position: "bottom-center",
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
            });
        }
    };
};
