// * React imports
import React, { useEffect, useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

// * Third Party imports
import NavBar from "../../components/navbar/navbar.component";
import SideBar from "../../components/sidebar/sidebar.component";
import moment from "moment";
import Select from "react-select";

// * MUI imports
import {
  Box,
  Table,
  TableHead,
  TableBody,
  TableCell,
  TablePagination,
  TableRow,
  TableSortLabel,
  TableContainer,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import IconButton from "@mui/material/IconButton";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import LastPageIcon from "@mui/icons-material/LastPage";
import { visuallyHidden } from "@mui/utils";

// * action file imports
import { getCODReport, downloadCODReport } from "../../state/actions";
import { getCustomers } from "../../state/actions/rider.actions";

function TablePaginationActions(props) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}

function getNestedProperty(obj, path) {
  const pathArray = path.split(".");
  return pathArray.reduce(
    (acc, key) => (acc && acc[key] !== "undefined" ? acc[key] : undefined),
    obj
  );
}

function descendingComparator(a, b, orderBy) {
  let valueA = getNestedProperty(a, orderBy);
  let valueB = getNestedProperty(b, orderBy);

  if (typeof valueA === "string" && typeof valueB === "string") {
    valueA = valueA.toUpperCase();
    valueB = valueB.toUpperCase();
  }

  if (valueB < valueA) {
    return -1;
  }
  if (valueB > valueA) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array?.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  { id: "orderNo", numeric: false, disablePadding: true, label: "ORDER NO" },
  {
    id: "orderDate",
    numeric: false,
    disablePadding: false,
    label: "ORDER DATE",
  },
  {
    id: "customer.firstName",
    numeric: false,
    disablePadding: false,
    label: "CUSTOMER",
  },
  {
    id: "orderPrice.actualValue",
    numeric: false,
    disablePadding: false,
    label: "TRANSACTION AMOUNT",
  },
  {
    id: "cashonDelivery",
    numeric: false,
    disablePadding: false,
    label: "CASH ON DELIVERY",
  },
  {
    id: "cashHandling",
    numeric: false,
    disablePadding: false,
    label: "CASH MANAGEMENT FEE",
  },
  {
    id: "refund",
    numeric: false,
    disablePadding: false,
    label: "CUSTOMER REFUND",
  },
];

function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

const CODReport = () => {
  const dispatch = useDispatch();
  const codReports = useSelector((state) => state.orders.codReport);
  const customers = useSelector((state) => state.riders.customers);
  const isLoading = useSelector((state) => state.orders.status);
  const isDownload = useSelector((state) => state.orders.download);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [searchParam, setSearchParam] = useState("");
  const [firstName, setFirstName] = useState();
  const [options, setOptions] = useState([]);
  const [startDate, setStartDate] = useState(
    moment(new Date()).format("YYYY-MM-DD")
  );
  const [endDate, setEndDate] = useState(
    moment(new Date()).format("YYYY-MM-DD")
  );
  const searchValues = {
    firstName: firstName ? firstName.value : "",
    startDate: startDate,
    endDate: endDate,
  };

  const handleNameChange = (data) => {
    console.log("selected", data);
    setFirstName(data);
  };

  const filteredReport = useCallback(() => {
    if (!searchParam) {
      return codReports;
    }

    return codReports.filter((item) => {
      // Convert the search parameter to lowercase for case-insensitive search
      const searchTerm = searchParam.toLowerCase();

      const containsSearchTerm = Object.values(item).some((value) => {
        if (typeof value === "object" && value !== null) {
          return Object.values(value).some((nestedValue) =>
            String(nestedValue).toLowerCase().includes(searchTerm)
          );
        }
        return String(value).toLowerCase().includes(searchTerm);
      });

      return containsSearchTerm;
    });
  }, [codReports, searchParam]);

  console.log("codReport", codReports);

  const handleSearchValues = (e) => {
    e.preventDefault();
    console.log("searchValues:", searchValues);
    dispatch(getCODReport(searchValues));
  };

  const handleExport = () => {
    dispatch(downloadCODReport(searchValues));
  };

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - codReports.length) : 0;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const search = (e) => {
    const { value } = e.target;
    setSearchParam(value);
  };

  useEffect(() => {
    dispatch(getCODReport(searchValues));
    dispatch(getCustomers()).then((res) => {
      const custs = res.map((c) => ({
        label: c.companyName
          ? c.firstName + " " + c.lastName + " ( " + c.companyName + " )"
          : c.firstName + " " + c.lastName,
        value: c.firstName,
      }));
      const custSorted = custs.sort((a, b) => {
        const nameA = a.value?.toLowerCase();
        const nameB = b.value?.toLowerCase();
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
        return 0;
      });
      setOptions(custSorted);
      console.log("options", options);
    });
  }, [dispatch]);

  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("orderNo");

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const visibleRows = React.useMemo(
    () =>
      stableSort(filteredReport(), getComparator(order, orderBy)).slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage
      ),
    [filteredReport, order, orderBy, page, rowsPerPage]
  );

  return (
    <>
      <NavBar />
      <SideBar />

      <div class="content-page">
        <div class="container-fluid">
          <div class="row">
            <div class="col-sm-12">
              <div class="card">
                <div class="card-header d-flex justify-content-between">
                  <div class="header-title">
                    <h4 class="card-title">Cash on Delivery List</h4>
                  </div>
                  {codReports.length > 0 ? (
                    <div className="">
                      {isDownload === "idle" ? (
                        <button
                          type="button"
                          className="btn btn-md btn-primary"
                          onClick={() => handleExport()}
                        >
                          Export Report
                        </button>
                      ) : (
                        <button className="btn btn-md btn-primary" disabled>
                          Downloading...
                        </button>
                      )}
                    </div>
                  ) : (
                    <div className=""></div>
                  )}
                </div>
                <div class="card-body">
                  <form onSubmit={handleSearchValues} className="">
                    <div className="row">
                      <div className="col-lg-11 col-xl-11">
                        <div className="row">
                          <div className="col-sm-6 col-lg-4 d-flex align-items-center pl-0 mb-2">
                            <p
                              style={{ fontSize: "14px" }}
                              className="col-6 col-sm-4 col-lg-5 col-xl-4 text mb-0"
                            >
                              FirstName:
                            </p>
                            <Select
                              className="col-6 col-sm-7 col-xl-8 px-0"
                              options={options}
                              value={firstName}
                              isSearchable={true}
                              isClearable={true}
                              onChange={handleNameChange}
                            />
                          </div>
                          <div className="col-sm-6 col-lg-4 d-flex align-items-center pl-0 mb-2">
                            <p
                              style={{ fontSize: "14px" }}
                              className="col-6 col-sm-4 col-lg-5 col-xl-4 text mb-0"
                            >
                              Start Date:
                            </p>
                            <input
                              className="col-6 col-sm-7 col-xl-8 form-control form-control-sm"
                              name="earnPercentage"
                              type="date"
                              value={startDate}
                              onChange={(e) => setStartDate(e.target.value)}
                            />
                          </div>
                          <div className="col-sm-6 col-lg-4 d-flex align-items-center pl-0 mb-2">
                            <p
                              style={{ fontSize: "14px" }}
                              className="col-6 col-sm-4 col-lg-5 col-xl-4 text mb-0"
                            >
                              End Date:
                            </p>
                            <input
                              className="col-6 col-sm-7 col-xl-8 form-control form-control-sm"
                              name="endDate"
                              type="date"
                              value={endDate}
                              onChange={(e) => setEndDate(e.target.value)}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-1 col-xl-1 d-flex justify-content-end">
                        <button
                          type="submit"
                          className="btn btn-sm btn-primary"
                        >
                          Submit
                        </button>
                      </div>
                    </div>
                  </form>
                  <div>
                    {isLoading === "loading" ? (
                      <div class="text-center mt-4">
                        <div class="spinner-border text-danger" role="status">
                          <span class="sr-only">Loading...</span>
                        </div>
                        <p className="small">Fetching Orders...</p>
                      </div>
                    ) : codReports.length > 0 ? (
                      <div className="table-responsive">
                        <Box>
                          <div className="form-group">
                            <label className="form-label">Search</label>
                            <input
                              type="text"
                              onChange={search}
                              className="form-control form-control-sm"
                            />
                          </div>
                        </Box>
                        <TableContainer>
                          <Table aria-label="sticky table">
                            <EnhancedTableHead
                              order={order}
                              orderBy={orderBy}
                              onRequestSort={handleRequestSort}
                              rowCount={rowsPerPage.length}
                            />
                            <TableBody>
                              {visibleRows?.map((cod, index) => (
                                <TableRow hover tabindex={-1} key={index}>
                                  <TableCell>{cod.orderNo}</TableCell>
                                  <TableCell>
                                    {" "}
                                    {moment(cod.orderDate).format(
                                      "YYYY-MM-DD hh:mm"
                                    )}
                                  </TableCell>
                                  <TableCell>
                                    {" "}
                                    {cod.customer?.firstName}{" "}
                                    {cod.customer?.lastName}{" "}
                                  </TableCell>
                                  <TableCell>
                                    {" "}
                                    &#8358;
                                    {`${cod.orderPrice.actualValue.toLocaleString(
                                      "ng-EG"
                                    )} `}{" "}
                                  </TableCell>
                                  <TableCell>
                                    {" "}
                                    &#8358;
                                    {`${cod.cashonDeliveryReceived.toLocaleString(
                                      "ng-EG"
                                    )} `}{" "}
                                  </TableCell>
                                  <TableCell>
                                    {" "}
                                    &#8358;
                                    {`${(
                                      cod.cashonDeliveryReceived * 0.01
                                    ).toLocaleString("ng-EG")} `}{" "}
                                  </TableCell>
                                  <TableCell>
                                    {" "}
                                    &#8358;
                                    {`${(
                                      cod.cashonDeliveryReceived * 0.99
                                    ).toLocaleString("ng-EG")} `}{" "}
                                  </TableCell>
                                </TableRow>
                              ))}
                              {emptyRows > 0 && (
                                <TableRow style={{ height: 53 * emptyRows }}>
                                  <TableCell colSpan={6} />
                                </TableRow>
                              )}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </div>
                    ) : (
                      <div className="text-center mx-auto mt-4">
                        <p className="text-Center">
                          No Cash on Delivery available currently
                        </p>
                      </div>
                    )}
                  </div>
                  <div>
                    <TablePagination
                      rowsPerPageOptions={[
                        5,
                        10,
                        25,
                        50,
                        { label: "All", value: -1 },
                      ]}
                      component="div"
                      count={filteredReport().length}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      SelectProps={{
                        inputProps: {
                          "aria-label": "rows per page",
                        },
                        native: true,
                      }}
                      onPageChange={handleChangePage}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                      ActionsComponent={TablePaginationActions}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CODReport;
