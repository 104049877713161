// * React imports
import React, { useEffect, useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

// * Third Party imports
import NavBar from "../../components/navbar/navbar.component";
import SideBar from "../../components/sidebar/sidebar.component";
import Select from "react-select";
import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

// * MUI imports
import {
    Dialog,
    DialogContent,
    DialogTitle,
    Button,
    FormControl,
    InputLabel,
    DialogActions,
    CircularProgress,
    Typography
} from "@mui/material";
import Table from "@mui/material/Table";
import Box from "@mui/material/Box";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Checkbox from '@mui/material/Checkbox';
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";

// * action file imports
import { getShipments, downloadTemplate, importShipment, createRcs, createDropOffBag, getCities, getDrivers } from "../../state/actions";
import {
  getCustomers
} from "../../state/actions/rider.actions";

function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }
  
  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

const DailyShipments = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const init_payload = {
    CustomerId: "",
    OriginId: "",
    AccountNumber: "",
    DateReceived: new Date(),
    File: null
  }

  const shipments = useSelector((state) => state.orders.shipments);
  const customers = useSelector((state) => state.riders.customers);
  const cities = useSelector((state) => state.orders.cities);
  const drivers = useSelector((state) => state.orders.drivers);
  const isLoading = useSelector((state) => state.orders.status);
  const isDownload = useSelector((state) => state.orders.downloading);
  const isPosting = useSelector((state) => state.orders.posting);
  const isCreating = useSelector((state) => state.orders.creating);
  const [newShipments, setNewShipments] = useState([]);
  const [pickedShipments, setPickedShipments] = useState([]);
  const [shipmentPayload, setShipmentPayload] = React.useState(init_payload);
  const [customer, setCustomer] = useState();
  const [searchCust, setSearchCust] = useState();
  const [origin, setOrigin] = useState();
  const [options, setOptions] = useState([]);
  const [cityOptions, setCityOptions] = useState();
  const [driverOptions, setDriverOptions] = useState([]);
  const [driver, setDriver] = useState();
  const [selected, setSelected] = useState([]);
  const [value, setValue] = React.useState(0);
  const [openDownload, setOpenDownload] = useState(false);
  const [openImport, setOpenImport] = useState(false);
  const [openRcs, setOpenRcs] = useState(false);
  const [openDropOff, setOpenDropOff] = useState(false);
  const [importView, setImportView] = useState(0);
  const [downloading, setDownloading] = useState(false);

  const handleNameChange = (data) => {
    console.log('selected', data)
    setSearchCust(data)
  }
  const handleNameImportChange = (data) => {
    console.log('selected', data)
    setCustomer(data)
  }
  const handleCityChange = (data) => {
    setOrigin(data)
  }
  const handleDriverChange = (data) => {
    setDriver(data)
  }

  console.log("shipments", shipments);

  const handleOpenDownload = () => {
    setOpenDownload(true)
  }
  const handleCloseDownload = () => [
    setOpenDownload(false)
  ]

  const handleOpenImport = () => {
    setOpenImport(true)
    setImportView(0);;
  };
  const handleCloseImport = () => {
    setOpenImport(false);
    setOrigin();
    setCustomer();
  };

  const handleOpenRcs = () => {
    setOpenRcs(true)
  }
  const handleCloseRcs = () => {
    setOpenRcs(false)
  }
  const handleOpenDropff = () => {
    setOpenDropOff(true)
  }
  const handleCloseDropOff = () => {
    setOpenDropOff(false)
  }

  const today = new Date()
  today.setDate(new Date().getDate() - 15);
  const searchValues = {
    id: searchCust ? searchCust.value : '',
    start: today.toISOString(),
    end: new Date().toISOString()
  }

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleSearchValues = (e) => {
    e.preventDefault();
    dispatch(getShipments(searchValues)).then((res) => {
        console.log('compShips', res);
      const newShip = res.shipments.filter(s => s.status === 0);
      const pickedShip = res.shipments.filter(s => s.status !== 0);
      setNewShipments(newShip);
      setPickedShipments(pickedShip);
    });
  };

  const handleDownload = () => {
    dispatch(downloadTemplate())
  };

  const handleFile = (e) => {
    const file = e.target.files[0];
    console.log("file", file);
    setShipmentPayload({
      ...shipmentPayload,
      File: file,
    });
  };

  const handleShipmentImport = () => {
    const formData = new FormData()
    formData.append("CustomerId", customer.value);
    formData.append("OriginId", origin.value);
    formData.append("AccountNumber", customer.value?.toString());
    formData.append("DateReceived", new Date());
    formData.append("File", shipmentPayload.File)
    setDownloading(true)
    importShipment(customer.value, formData).then((res) => {
        if(res.status === 200) {
        alert("Shipment uploaded successfully")
        setDownloading(false)
        const _searchValues = {
          id: customer ? customer.value : '',
          start: new Date().toISOString(),
          end: new Date().toISOString()
        }
        dispatch(getShipments(_searchValues)).then((res) => {
            const newShip = res.shipments.filter(s => s.status === 0);
            const pickedShip = res.shipments.filter(s => s.status !== 0);
            setNewShipments(newShip);
            setPickedShipments(pickedShip);
            
            handleCloseImport();
            handleCloseDownload();
        })
        }else{
          setDownloading(false)
        }
    })
  };

  const handleRowSelection = (rowData) => {
    const isSelected = selected.some((row) => row.id === rowData.id);

    if (isSelected) {
      setSelected(selected.filter((row) => row.id !== rowData.id));
    } else {
      setSelected([...selected, rowData]);
    }
  };

  const handleSelectAllRows = () => {
    if (selected.length === shipments.length) {
      setSelected([]);
    } else {
      setSelected([...shipments]);
    }
  };

  const handleGenerateRcs = async () => {
    const body = {
      customerId: searchCust.value,
      date: new Date(),
      driverId: driver ? driver.value : '',
      originId: origin?  origin.value: '',
      shipmentIds: selected
    }
    try {
        const result = await dispatch(createRcs(body));
        if (result && result.error) {
          console.log("error", result.error)
          toast.error(result.error)
          alert(result.error)
        } else {
            localStorage.setItem("currentCustomer", searchCust)
            alert("RCS generation successful")
          navigate("/rcs")
          console.log("success", 'Success creating')
        }
    } catch (error) {
        console.log("error2", error)
    }
  };
  const handleDropOffBag = async () => {
    const body = {
      date: new Date(),
      driverId: driver ? driver.value : '',
      originId: origin?  origin.value: '',
      shipmentIds: selected
    }
    try {
        const result = await dispatch(createDropOffBag(body));
        if (result && result.error) {
          console.log("error", result.error)
          toast.error(result.error)
          alert(result.error)
        } else {
            localStorage.setItem("currentCustomer", searchCust)
            alert("Pack bag have been discharge to LMS successful")
            window.location.reload();
        }
    } catch (error) {
        console.log("error2", error)
    }
  };

  useEffect(() => {
    dispatch(getCustomers()).then((res) => {
      console.log('resCust', res)
      const custSorted = res.sort((a, b) => {
        const nameA = a.firstName?.toLowerCase();
        const nameB = b.firstName?.toLowerCase();
        if(nameA < nameB) { return -1; }
        if(nameA > nameB) { return 1; }
        return 0;
      })
      const custs = custSorted.map(c => ({
        label: c.companyName ? c.firstName + " " + c.lastName + " ( " + c.companyName + " )" : c.firstName + " " + c.lastName,
        value: c.id
      }))
      setOptions(custs)
      console.log('options', options)
    })
    dispatch(getCities()).then((res) => {
      const cities = res.map(c => ({
        label: c.name,
        value: c.id
      }));
      setCityOptions(cities)
    });
    dispatch(getDrivers()).then((res) => {
      const drivers = res.sort((a, b) => {
        const nameA = a.firstName.toLowerCase();
        const nameB = a.lastName.toLowerCase();

        if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }
          return 0;
      })
      const driversSorted = drivers.map(d => ({
        label: d.firstName + ' ' + d.lastName,
        value: d.id
      }))
      setDriverOptions(driversSorted)
    })
  }, [dispatch]);


  return (
    <>
      <NavBar />
      <SideBar />

      <div class="content-page">
        <div class="container-fluid">
          <div class="row">
            <div class="col-sm-12">
              <div class="card">
                <div class="card-header d-flex justify-content-between">
                  <div class="header-title">
                    <h4 class="card-title">Daily Shipments</h4>
                  </div>
                    <div className="">
                      <button
                      type="button"
                      className="btn btn-md btn-primary"
                      onClick={handleOpenDownload}
                      >
                        Import Shipment
                      </button>
                    </div>
                </div>
                <div class="card-body">
                  <form onSubmit={handleSearchValues} className="">
                  <div className="row">
                      <div className="col-md-8">
                        <div className="d-flex mb-2">
                          <p style={{ fontSize: "14px" }} className="col-5 col-md-4 col-lg-3 mb-0">Select Customer:</p>
                          <Select
                          className="col-7 col-md-8 col-lg-9 px-0"
                          options={options}
                          value={customer}
                          isSearchable={true}
                          isClearable={true}
                          onChange={handleNameChange}
                          />
                        </div>
                      </div>
                      <div className="col-md-4 d-flex justify-content-end justify-content-md-start">
                        <button
                        type="submit"
                        className="btn btn-sm btn-primary"
                        onClick={handleSearchValues}
                        >
                          Submit
                        </button>
                      </div>
                    </div>
                  </form>
                  <div>
                  <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                    <Tabs
                    value={value}
                    onChange={handleChange}
                    aria-label="basic tabs example"
                    >
                    <Tab label="New" {...a11yProps(0)} />
                    <Tab label="Picked" {...a11yProps(1)} />
                    </Tabs>
                  </Box>
                   <CustomTabPanel value={value} index={0}>
                    <div className="d-flex justify-content-end">
                      <button
                      class="btn btn-sm btn-primary"
                      onClick={handleOpenRcs}
                      disabled={selected.length > 0 ? false : true}
                      >
                        Create RCS
                      </button> &nbsp;&nbsp;
                      <button
                      class="btn btn-sm btn-primary"
                      onClick={handleOpenDropff}
                      disabled={selected.length > 0 ? false : true}
                      >
                        Create Dropoff Bag
                      </button>
                    </div>
                    {isLoading === "loading" ? (
                      <div class="text-center mt-4">
                        <div class="spinner-border text-danger" role="status">
                          <span class="sr-only">Loading...</span>
                        </div>
                        <p className="small">Fetching Orders...</p>
                      </div>
                    ) : shipments.length > 0 ? (
                      <div className="table-responsive">
                        <Table aria-label="sticky table">
                          <TableHead>
                            <TableRow>
                              <TableCell>
                                <Checkbox
                                disabled={newShipments.length === 0}
                                checked={newShipments?.length > 0 && selected?.length === newShipments?.length}
                                onChange={handleSelectAllRows}
                                inputProps={{ 'aria-label': 'controlled' }}
                                />
                              </TableCell>
                              <TableCell>Order Number</TableCell>
                              <TableCell>Pickup</TableCell>
                              <TableCell>Origin</TableCell>
                              <TableCell>Destination</TableCell>
                              <TableCell>Content</TableCell>
                              <TableCell>Weight</TableCell>
                              <TableCell>Quantity</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {newShipments
                              ?.map((s, index) => (
                                <TableRow hover tabindex={-1} key={index}>
                                  <TableCell>
                                    <Checkbox
                                    checked={selected.some((selectedRow) => selectedRow.id === s.id)}
                                    onChange={() => handleRowSelection(s)}
                                    inputProps={{ 'aria-label': 'controlled' }}
                                    />
                                  </TableCell>
                                  <TableCell>
                                    { s.waybillNumber }
                                  </TableCell>
                                  <TableCell>
                                    { s.senderAddress }
                                  </TableCell>
                                  <TableCell>
                                    { s.origin.name }
                                  </TableCell>
                                  <TableCell>
                                    { s.city.name }
                                  </TableCell>
                                  <TableCell>
                                    { s.contentDescription }
                                  </TableCell>
                                  <TableCell>
                                    { s.weight + "kg" }
                                  </TableCell>
                                  <TableCell>
                                    { s.unit }
                                  </TableCell>
                                </TableRow>
                              ))}
                          </TableBody>
                        </Table>
                      </div>
                    ) : (
                      <div className="text-center mx-auto mt-4">
                        <p className="text-Center">
                          No Shipment Available Currently
                        </p>
                      </div>
                    )}
                    </CustomTabPanel>
                    <CustomTabPanel value={value} index={1}>
                    {isLoading === "loading" ? (
                      <div class="text-center mt-4">
                        <div class="spinner-border text-danger" role="status">
                          <span class="sr-only">Loading...</span>
                        </div>
                        <p className="small">Fetching Orders...</p>
                      </div>
                    ) : shipments.length > 0 ? (
                      <div className="table-responsive">
                        <Table aria-label="sticky table">
                          <TableHead>
                            <TableRow>
                              <TableCell>
                                <Checkbox
                                checked={shipments?.length > 0 && selected?.length === shipments?.length}
                                onChange={handleSelectAllRows}
                                inputProps={{ 'aria-label': 'controlled' }}
                                />
                              </TableCell>
                              <TableCell>Order Number</TableCell>
                              <TableCell>Origin</TableCell>
                              <TableCell>Destination</TableCell>
                              <TableCell>Content</TableCell>
                              <TableCell>Weight</TableCell>
                              <TableCell>Quantity</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {pickedShipments
                              ?.map((s, index) => (
                                <TableRow hover tabindex={-1} key={index}>
                                  <TableCell>
                                    <Checkbox
                                    checked={selected.some((selectedRow) => selectedRow.id === s.id)}
                                    onChange={() => handleRowSelection(s)}
                                    inputProps={{ 'aria-label': 'controlled' }}
                                    />
                                  </TableCell>
                                  <TableCell>
                                    { s.waybillNumber }
                                  </TableCell>
                                  <TableCell>
                                    { s.origin.name }
                                  </TableCell>
                                  <TableCell>
                                    { s.city.name }
                                  </TableCell>
                                  <TableCell>
                                    { s.contentDescription }
                                  </TableCell>
                                  <TableCell>
                                    { s.weight + "kg" }
                                  </TableCell>
                                  <TableCell>
                                    { s.unit }
                                  </TableCell>
                                </TableRow>
                              ))}
                          </TableBody>
                        </Table>
                      </div>
                    ) : (
                      <div className="text-center mx-auto mt-4">
                        <p className="text-Center">
                          No Shipment Available Currently
                        </p>
                      </div>
                    )}
                    </CustomTabPanel>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="dialogs">
        <div name="Template Download">
          <Dialog
            open={openDownload}
            onClose={handleCloseDownload}
          >
            <Box
              style={{ marginTop: "10px" }}
              sx={{
                maxWidth: "auto",
                display: "flex",
              }}
            >
              <Button style={{}} onClick={handleCloseDownload} color="error">
                <KeyboardBackspaceIcon />
              </Button>
              <DialogTitle style={{ paddingLeft: "0" }}>
                Bulk Order Request
              </DialogTitle>
            </Box>
            <DialogContent>
              <Box
              >
                <div className="">
                  <p
                  ><strong>-</strong> Click the button below to download the file template;</p>
                  <p
                  ><strong>-</strong> Ensure to foloow the format as given in the template;</p>
                  <p
                  ><strong>-</strong> For City, Town, and Payment Type, their corresponding number can be found in the worksheets named after them.</p>
                </div>
                <div className="">
                  <Button
                    style={{ textTransform: "capitalize", fontWeight: "700", width: "100%" }}
                    variant="outlined"
                    color="error"
                    onClick={handleDownload}
                    disabled={isDownload === "loading"}
                  >
                    { isDownload === "loading" ? 'Please wait...' : 'Download' }
                  </Button>
                </div>
              </Box>
            </DialogContent>
            <DialogActions>
              <Button
                style={{ textTransform: "capitalize", fontWeight: "700" }}
                variant="outlined"
                color="error"
                onClick={handleOpenImport}
              >
                Continue
              </Button>
            </DialogActions>
          </Dialog>
        </div>
        <div name="Bulk Request">
          <Dialog open={openImport} onClose={handleCloseImport}>
            <Box
              style={{ marginTop: "10px", marginBottom: "10px" }}
              sx={{
                maxWidth: "auto",
                display: "flex",
              }}
            >
              <Button style={{}} onClick={handleCloseImport} color="error">
                <KeyboardBackspaceIcon />
              </Button>
              <DialogTitle style={{ paddingLeft: "0" }}>
                Bulk Order Request
              </DialogTitle>
            </Box>
            <DialogContent>
              <Box>
                <div className="">
                  <InputLabel id="demo-select-small-label">Customer</InputLabel>
                    <Select
                    className="w-100"
                    options={options}
                    value={customer}
                    isSearchable={true}
                    isClearable={true}
                    onChange={handleNameImportChange}
                    />
                    <InputLabel id="demo-select-small-label">Origin</InputLabel>
                    <Select
                    className="w-100"
                    options={cityOptions}
                    value={ origin}
                    isSearchable={true}
                    isClearable={true}
                    onChange={handleCityChange}
                    />
                    <InputLabel id="demo-select-small-label">Upload File (Excel only)</InputLabel>
                  <FormControl fullWidth sx={{ m: 1 }} size="small">
                    <input className="" type="file" onChange={handleFile} />
                  </FormControl>
                </div>
              </Box>
            </DialogContent>
            <DialogActions>
              {downloading ? (
                <Button
                  style={{
                    textTransform: "capitalize",
                    fontWeight: "700",
                    border: "none",
                  }}
                  variant="outlined"
                  color="error"
                >
                  <CircularProgress />
                </Button>
              ) : (
                <Button
                  style={{ textTransform: "capitalize", fontWeight: "700" }}
                  variant="outlined"
                  color="error"
                  onClick={handleShipmentImport}
                >
                  Create
                </Button>
              )}
            </DialogActions>
          </Dialog>
        </div>
        <div name="RCS Generate">
          <Dialog open={openRcs} onClose={handleCloseRcs}>
            <Box
              style={{ marginTop: "10px", marginBottom: "10px" }}
              sx={{
                maxWidth: "auto",
                display: "flex",
              }}
            >
              <Button style={{}} onClick={handleCloseRcs} color="error">
                <KeyboardBackspaceIcon />
              </Button>
              <DialogTitle style={{ paddingLeft: "0" }}>
                Generate RCS
              </DialogTitle>
            </Box>
            <DialogContent>
              <Box>
                <div className="">
                  <div className="mb-3">
                  <InputLabel id="demo-select-small-label">Origin</InputLabel>
                  <Select
                    className="w-100"
                    options={cityOptions}
                    value={origin}
                    isSearchable={true}
                    isClearable={true}
                    onChange={handleCityChange}
                  />
                  </div>
                  <div className="mb-3">
                  <InputLabel id="demo-select-small-label">Driver</InputLabel>
                  <Select
                    className="w-100"
                    options={driverOptions}
                    value={driver}
                    isSearchable={true}
                    isClearable={true}
                    onChange={handleDriverChange}
                  />
                  </div>
                </div>
              </Box>
            </DialogContent>
            <DialogActions>
              {isCreating === "loading" ? (
                <Button
                  style={{
                    textTransform: "capitalize",
                    fontWeight: "700",
                    border: "none",
                  }}
                  variant="outlined"
                  color="error"
                >
                  <CircularProgress />
                </Button>
              ) : (
                <Button
                  style={{ textTransform: "capitalize", fontWeight: "700" }}
                  variant="outlined"
                  color="error"
                  onClick={handleGenerateRcs}
                >
                  Create
                </Button>
              )}
            </DialogActions>
          </Dialog>
          <Dialog open={openDropOff} onClose={handleCloseDropOff}>
            <Box
              style={{ marginTop: "10px", marginBottom: "10px" }}
              sx={{
                maxWidth: "auto",
                display: "flex",
              }}
            >
              <Button style={{}} onClick={handleCloseDropOff} color="error">
                <KeyboardBackspaceIcon />
              </Button>
              <DialogTitle style={{ paddingLeft: "0" }}>
                Generate DropOff Bag
              </DialogTitle>
            </Box>
            <DialogContent>
              <Box>
                <div className="">
                  <div className="mb-3">
                  <InputLabel id="demo-select-small-label">Origin</InputLabel>
                  <Select
                    className="w-100"
                    options={cityOptions}
                    value={origin}
                    isSearchable={true}
                    isClearable={true}
                    onChange={handleCityChange}
                  />
                  </div>
                  <div className="mb-3">
                  <InputLabel id="demo-select-small-label">Driver</InputLabel>
                  <Select
                    className="w-100"
                    options={driverOptions}
                    value={driver}
                    isSearchable={true}
                    isClearable={true}
                    onChange={handleDriverChange}
                  />
                  </div>
                </div>
              </Box>
            </DialogContent>
            <DialogActions>
              {isCreating === "loading" ? (
                <Button
                  style={{
                    textTransform: "capitalize",
                    fontWeight: "700",
                    border: "none",
                  }}
                  variant="outlined"
                  color="error"
                >
                  <CircularProgress />
                </Button>
              ) : (
                <Button
                  style={{ textTransform: "capitalize", fontWeight: "700" }}
                  variant="outlined"
                  color="error"
                  onClick={handleDropOffBag}
                >
                  Create
                </Button>
              )}
            </DialogActions>
          </Dialog>
        </div>
        </div>
      </div>
    </>
  );
};

export default DailyShipments;
